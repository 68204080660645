import { defineComponent } from '@vue/composition-api';

import Card from '@/components/Card';
import { Empty } from '@/components/UI';

export default defineComponent({
	components: { Card, Empty },
	setup() {
		return {};
	},
});
